import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import PageTemplate from "@pageTemplates/CaseStudiesTestimonialsPage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query {
    craft {
      entry(section: "caseStudiesTestimonials") {
        ... on Craft_caseStudiesTestimonials_caseStudiesTestimonials_Entry {
          title
          slug
          url
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroBackgroundImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          pageBuilder {
            ...PageBuilderFragment
          }
          # meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(
        section: ["caseStudies", "testimonials"]
        orderBy: "postDate DESC"
        boolean0: false
      ) {
        ... on Craft_caseStudies_default_Entry {
          type: sectionHandle
          uid
          title
          description: metaDescription
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          caseStudyCategory {
            ... on Craft_caseStudyCategory_Category {
              uid
              slug
              title
            }
          }
          url
        }
        ... on Craft_testimonials_default_Entry {
          uid
          type: sectionHandle
          quote: descriptor0
          name: heading0
          position: heading1
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          caseStudyCategory {
            ... on Craft_caseStudyCategory_Category {
              uid
              slug
              title
            }
          }
          videoUrl
        }
      }
      categories(group: "caseStudyCategory") {
        ... on Craft_caseStudyCategory_Category {
          uid
          slug
          title
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries, categories }) => {
  const {
    pageBuilder,
    heroHeading,
    heroDescriptor,
    heroBackgroundImage,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
      image: resolveImage(heroBackgroundImage),
      small: true,
    },
    blocks: resolvePageBuilder(pageBuilder),
    entries: entries.map(e => {
      const { title: tag } = e?.caseStudyCategory[0] || {};
      return { ...e, image: resolveImage(e.image0), tag };
    }),
    categories: [{ title: "All", uid: "all", slug: "all" }, ...categories],
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CaseStudiesTestimonialsDefaultQuery"
    {...props}
  />
);

export default Page;
